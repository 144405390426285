<template>
  <div>
    <Navigation />
    <div v-if="clients && projects && workstations && employees">
      <div class="pa-5 flex items-start">
        <!-- workstations filter -->
        <ElSelect
          class="mr-1"
          v-model="filters.workstationIds"
          filterable
          clearable
          multiple
          default-first-option
          placeholder="Toate Posturile de Lucru"
        >
          <ElOption
            v-for="workstation in workstations"
            :key="workstation.id"
            :value="workstation.id"
            :label="workstation.data.name"
          />
        </ElSelect>
        <!-- employee filter -->
        <ElSelect
          class="mr-1"
          v-model="filters.employeeIds"
          filterable
          clearable
          multiple
          default-first-option
          placeholder="Toti Angajatii"
        >
          <ElOption
            v-for="employee in employees"
            :key="employee.id"
            :value="employee.id"
            :label="employee.data.name"
          />
        </ElSelect>
        <!-- project filter -->
        <ElSelect
          class="mr-1"
          v-model="filters.projectId"
          filterable
          clearable
          default-first-option
          placeholder="Toate Lucrarile"
        >
          <ElOption
            v-for="project in projects"
            :key="project.id"
            :value="project.id"
            :label="project.data.name"
          />
        </ElSelect>
        <!-- client filter -->
        <ElSelect
          class="mr-1"
          v-model="filters.clientId"
          filterable
          clearable
          default-first-option
          placeholder="Toti Clientii"
        >
          <ElOption
            v-for="clientId in clientIdsFromProjects"
            :key="clientId"
            :value="clientId"
            :label="clientsById[clientId].data.name"
          />
        </ElSelect>
        <!-- mode filter -->
        <ElSelect class="mr-1" v-model="filters.mode">
          <ElOption value="ready" label="Pe rand" />
          <ElOption value="active" label="In Lucru" />
          <ElOption value="all" label="Toate" />
        </ElSelect>
      </div>
      <!-- table -->
      <div style="height:85vh">
        <ElTable
          style="width: 100%;height:80vh; overflow: scroll;"
          :data="filteredTasks"
          :row-class-name="getRowClassName"
          @row-click="row => $refs.taskDialog.open(row)"
        >
          <ElTableColumn type="index" width="50" />
          <ElTableColumn label="Nume">
            <template slot-scope="{ row }">
              <div class="font-bold">
                {{ row.name }}
                <span v-if="row.component">{{ row.component.name }}</span>
              </div>
              <div v-if="row.project.data.clientId">
                <span class="mr-1">#{{ row.project.data.number }}</span>
                <span class="mr-1">{{ row.project.data.name }}</span>
                <ElTag
                  type="success"
                  size="mini"
                  v-if="row.project.data.fsc"
                  class="mr-1"
                  >FSC</ElTag
                >
                ({{ clientsById[row.project.data.clientId].data.name }})
              </div>
            </template>
          </ElTableColumn>
          <ElTableColumn>
            <template slot-scope="{ row }">
              <div v-if="row.break">
                <i class="el-icon-warning"></i>In pauza
              </div>
            </template>
          </ElTableColumn>
          <ElTableColumn label="Posturi" width="200">
            <template slot-scope="{ row }">
              <span
                v-for="workstationId in row.workstationIds"
                :key="workstationId"
                >{{ workstationsById[workstationId].data.name }}</span
              >
            </template>
          </ElTableColumn>
          <ElTableColumn label="Angajati" width="160">
            <template slot-scope="{ row }" v-if="row.employeeIds">
              {{
                row.employeeIds
                  .map(id =>
                    employeesById[id] ? employeesById[id].data.name : "???"
                  )
                  .join(", ")
              }}
            </template>
          </ElTableColumn>
        </ElTable>
      </div>
      <!-- dialogs -->
      <TaskDialog
        ref="taskDialog"
        :employees="employees"
        :clientsById="clientsById"
        :printersById="printersById"
        :operationsById="operationsById"
        :materialsById="materialsById"
        :workstationsById="workstationsById"
      />
    </div>
  </div>
</template>

<script>
import { keyBy, uniq, intersection, orderBy } from "lodash";
import PersistFieldMixin from "@/mixins/PersistFieldMixin";
import TaskDialog from "@/components/tasks/TaskDialog";
import { getProjectGraph } from "@/utils/graph";

export default {
  mixins: [PersistFieldMixin("filters")],
  components: {
    TaskDialog
  },
  data() {
    return {
      filters: {
        mode: "ready",
        clientId: null,
        projectId: null,
        employeeIds: [],
        workstationIds: []
      },
      clients: null,
      printers: null,
      operations: null,
      materials: null,
      employees: null,
      workstations: null,
      rawProjects: null
    };
  },
  created() {
    this.$bind(
      "rawProjects",
      this.$firestore()
        .collection("projects")
        .where("stage", "==", "active")
    );
    this.$bind("clients", this.$firestore().collection("clients"));
    this.$bind("printers", this.$firestore().collection("printers"));
    this.$bind("operations", this.$firestore().collection("operations"));
    this.$bind("materials", this.$firestore().collection("materials"));
    this.$bind("employees", this.$firestore().collection("employees"));
    this.$bind("workstations", this.$firestore().collection("workstations"));
  },
  computed: {
    projects() {
      return orderBy(
        this.rawProjects,
        ["data.priority", "data.createdAt"],
        ["desc", "asc"]
      ).map(project => ({
        ...project,
        graph: getProjectGraph(project.data)
      }));
    },
    clientsById() {
      return keyBy(this.clients, "id");
    },
    projectsById() {
      return keyBy(this.rawProjects, "id");
    },
    printersById() {
      return keyBy(this.printers, "id");
    },
    operationsById() {
      return keyBy(this.operations, "id");
    },
    materialsById() {
      return keyBy(this.materials, "id");
    },
    employeesById() {
      return keyBy(this.employees, "id");
    },
    workstationsById() {
      return keyBy(this.workstations, "id");
    },
    tasks() {
      if (this.filters.mode === "ready") {
        return this.getReadyTasks();
      }

      if (this.filters.mode === "active") {
        return this.getActiveTasks();
      }

      return this.getAllTasks();
    },
    clientIdsFromProjects() {
      return uniq(
        this.projects
          .map(projects => projects.data.clientId)
          .filter(clientId => !!clientId)
      );
    },
    filteredTasks() {
      const { filters } = this;
      return this.tasks.filter(task => {
        if (
          filters.workstationIds.length > 0 &&
          intersection(task.workstationIds, filters.workstationIds).length === 0
        ) {
          return false;
        }
        if (
          filters.employeeIds.length > 0 &&
          intersection(task.employeeIds, filters.employeeIds).length === 0
        ) {
          return false;
        }
        if (filters.projectId && task.project.id !== filters.projectId) {
          return false;
        }
        if (
          filters.clientId &&
          task.project.data.clientId !== filters.clientId
        ) {
          return false;
        }
        return true;
      });
    }
  },
  methods: {
    getReadyTasks() {
      return this.projects.flatMap(project => {
        return project.graph
          .nodes("[type='task'][!completed]")
          .filter(node => node.incomers("[type='task']").allAre("[?completed]"))
          .map(node => this.getTaskObject(node, project));
      });
    },
    getActiveTasks() {
      return this.projects.flatMap(project => {
        return project.graph
          .nodes("[type='task'][?active]")
          .map(node => this.getTaskObject(node, project));
      });
    },
    getAllTasks() {
      return this.projects.flatMap(project => {
        return project.graph
          .nodes("[type='task']")
          .map(node => this.getTaskObject(node, project));
      });
    },
    getTaskObject(node, project) {
      const data = node.data();
      const component = node.isChild() ? node.parent().data() : null;
      const predecessors = node
        .predecessors("[type='task']")
        .map(node => node.data());
      const workstationIds = this.getWorkstationIds(data.operations);
      return {
        ...data,
        project,
        component,
        predecessors,
        workstationIds
      };
    },
    getWorkstationIds(operations) {
      //nu faci check if undefined in mapping , nu e bine bad practice .de anuntat andrei !
      const { operationsById } = this;
      let ops = operations
        .map(({ operationId }) =>
          operationsById[operationId] !== undefined // hf adaugat de raul
            ? operationsById[operationId].data.workstationId
            : null
        )
        .filter(workstationId => workstationId);
      return uniq(ops);
    },
    getRowClassName({ row: task }) {
      if (task.project.data.priority) {
        return "bg-red-200";
      }
    }
  }
};
</script>
