export default fieldName => ({
  created() {
    const content = localStorage.getItem(fieldName);
    if (content) this[fieldName] = JSON.parse(content);
  },
  watch: {
    [fieldName]: {
      deep: true,
      handler() {
        const content = JSON.stringify(this[fieldName]);
        localStorage.setItem(fieldName, content);
      }
    }
  }
});
