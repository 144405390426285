<template>
  <ElDialog fullscreen :visible.sync="visible" :title="title" custom-class="w-max m-5">
    <div slot="title" v-if="task" class="text-lg text-gray-900" style="width:1200px">
      {{ task.name }} {{ task.component && task.component.name }} &mdash; #{{
        task.project.data.number
      }}
      {{ task.project.data.name }} &mdash;
      {{
        task.project.data.clientId
          ? clientsById[task.project.data.clientId].data.name
          : "???"
      }}
    </div>
    <el-tabs v-if="task" v-model="currentTab">
      <el-tab-pane label="Incepe sarcina" name="0">
        <div class="flex" v-if="task">
          
          <div class="flex-1">
            <ElAlert
              v-if="task.employeeIds.length === 0"
              type="warning"
              show-icon
              class="mb-4"
              :closable="false"
              title="Sarcina nu poate fi inceputa deoarece nu s-a asociat nici un angajat"
            />
            <ElAlert
              v-if="task.alert"
              type="error"
              show-icon
              class="mb-4"
              :closable="false"
              :title="task.alert"
            />
            <!-- angajati -->
            <div class="flex items-center h-10 mb-2">
              <label class="block w-32">Angajati</label>
              <ElSelect
                class="flex-1"
                v-model="task.employeeIds"
                filterable
                clearable
                multiple
                default-first-option
                placeholder="Selecteaza"
                @change="updateEmployees"
              >
                <ElOption
                  v-for="employee in employees"
                  :key="employee.id"
                  :value="employee.id"
                  :label="employee.data.name"
                />
              </ElSelect>
            </div>
          </div>
        </div>
        <el-button @click="toggleActive(false)" v-if="task.active" class="mt-5" icon="el-icon-close" type="warning">seteaza ca ne-inceputa</el-button>
        <el-button @click="toggleActive(true)" v-else class="mt-5" icon="el-icon-check" type="success">incepe lucrare</el-button >

      </el-tab-pane>

      <el-tab-pane label="Informatii lucrare" name="1">
        <div class="flex items-center mb-2 font-bold">
          <div class="w-32 tr">Tiraj</div>
          <div v-if="task.project.data.variables.quantity" class="flex-1">
            {{ task.project.data.variables.quantity | number }}
          </div>
        </div>
        <div class="flex mb-2">
          <div class="w-32">Rezultate</div>
          <div class="flex-1">
            <div v-if="task.predecessors.length === 0">nu exista</div>
            <div v-for="predecessor in task.predecessors" :key="predecessor.id">
              {{ predecessor.name }} &mdash; {{ predecessor.result }}
            </div>
          </div>
        </div>

        <!-- operations -->
        <div class="flex mb-2">
          <div class="w-32">Operatiuni</div>
          <div class="flex-1">
            <div v-if="operations.length === 0">nu exista</div>
            <div v-for="({ count, value }, index) in operations" :key="index">
              {{ operationsById[value.operationId].data.name }}
              <template
                v-if="operationsById[value.operationId].data.parameterName"
              >
                <span class="font-bold"
                  >{{ evaluate(value.parameter) }}
                  {{ operationsById[value.operationId].data.parameterName }}
                </span>
              </template>
              &mdash; {{ count }} x {{ evaluate(value.quantity) | number }} =
              {{ (count * evaluate(value.quantity)) | number }}
            </div>
          </div>
        </div>

        <div class="flex mb-2">
          <div class="w-32">Descriere</div>
          <div v-if="task.project.data.description" class="flex-1">
            <ElInput
              v-model="task.project.data.description"
              type="textarea"
              :autosize="{ minRows: 3 }"
              class="flex-1"
            />
          </div>
          <div v-else>-</div>
        </div>

        <div class="flex mb-2">
          <div class="w-32">Poza</div>
          <div v-if="task.project.data" class="flex-1">
            <img style="width:80vh" :src="task.project.data.imageUrl" />
          </div>
          <div v-else>-</div>
        </div>

        <el-button @click="nextStage()" v-if="!hasReadInstructions" class="mt-5" icon="el-icon-check" type="success">am citit</el-button>
      </el-tab-pane>

      <el-tab-pane :disabled="!task.active || !hasReadInstructions" label="Scade stoc" name="2">
        <div class="flex mb-5 flex-row justify-between">
          <div>
            <el-button @click="toggleActive(false)" v-if="task.active"  type="warning" icon="el-icon-close">seteaza lucrare in pauza</el-button>
            <el-button @click="toggleActive(true)" v-else  type="success" icon="el-icon-refresh">incepe lucru acum</el-button>
          </div>


          <el-button @click="checkToggle(true)" type="primary" icon="el-icon-check">finalizare lucrare</el-button>
        </div>
        <template v-if="task.component">
          <div class="flex mb-2">
            <div class="w-32">Hartie</div>
            <div class="flex-1">
              <ResourceLoader
                :path="
                  `materialCategories/${task.component.request.sheetCategoryId}`
                "
              >
                <span slot-scope="{ resource }">{{ resource.data.name }}</span>
              </ResourceLoader>
              {{ task.component.request.sheetWeight }}g
            </div>
          </div>
          <div class="flex mb-2">
            <div class="w-32">Pagini</div>
            <div class="flex-1">
              {{ task.component.request.pages }}
            </div>
          </div>
          <div class="flex">
            <div class="w-32">Culori</div>
            <div class="flex-1">
              {{ task.component.request.frontColors }} fata +
              {{ task.component.request.backColors }} spate
              {{ task.component.request.varnish ? "+ lacuire" : "" }}
            </div>
          </div>
        </template>
        <!-- materials -->
        <div class="flex mt-3">
          <div class="w-32">
            Materiale
            <ElTag
              type="success"
              size="mini"
              v-if="task.project.data.fsc"
              class="ml-1"
              >FSC</ElTag
            >
          </div>
          <div class="flex-1">
            <div v-if="materials.length === 0">nu exista</div>
            <div>
              <div
                class="flex"
                v-for="({ count, value, stoc }, index) in materials"
                :key="index"
              >
                <div class="flex-grow pt-5">
                  <span
                    v-if="materialsById[value.materialId]"
                    class="font-bold"
                    >{{ materialsById[value.materialId].data.name }}</span
                  >
                  <span v-else> Lipsa material ( {{ value.materialId }}) </span>
                  &mdash;
                  {{ count }} x {{ evaluate(value.quantity) }} =
                  {{ (count * evaluate(value.quantity)) | number }}
                </div>
                <span
                  v-if="task.employeeIds.length"
                  class="pt-3 mr-2 font-bold text-red"
                >
                  <el-input-number
                    :step="1000"
                    @change="restrictNull(index, stocks[index])"
                    :disabled="task.materialsDebited"
                    style="width:200px;"
                    class="mr-2"
                    type="number"
                    v-model="stocks[index]"
                  ></el-input-number>
                </span>
                <!-- <el-input-number v-model="num" @change="handleChange" :min="1" :max="10"></el-input-number> -->
              </div>
              <div class="mt-2 flex" v-if="materials && materials.length">
                <ElButton
                  type="danger"
                  :disabled="task.employeeIds.length === 0"
                  @click="debitMaterials(false)"
                  v-if="!task.materialsDebited"
                  icon="el-icon-check"
                >
                  Scadere Stocuri (MANUAL)</ElButton
                >
                <ElButton
                  :disabled="task.employeeIds.length === 0"
                  @click="creditMaterials(true)"
                  v-else
                  type="success"
                  icon="el-icon-refresh-left"
                  >Returnare Stocuri</ElButton
                >
                <div class="flex-grow"></div>
                <div
                  v-if="employeesById[task.employeeIds[0]]"
                  class="flex-grow-0 font-bold"
                >
                  <el-alert
                    v-if="!task.materialsDebited"
                    type="error"
                    class="px-10"
                  >
                    <div class="mr-5">
                      <i type="primary" class="el-icon-warning"></i>
                      Stoc ne-consumat
                      {{ employeesById[task.employeeIds[0]].data.name }}
                    </div>
                  </el-alert>
                  <el-alert v-else type="success" class="px-10">
                    <div class="mr-5">
                      <i type="success" class="el-icon-top"></i>
                      Stoc consumat
                      {{ employeesById[task.employeeIds[0]].data.name }}
                    </div>
                  </el-alert>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="width:800px" class="flex my-2">
          <div class="w-32">Diagrame</div>
          <div class="flex-1">
            <div v-if="impositions.length === 0">nu exista</div>

            <div v-for="({ count, value }, index) in impositions" :key="index">
              <div class="mb-2">
                {{ count }} x {{ printersById[value.printerId].data.name }} ({{
                  value.distinctPages
                }}
                pagini{{ value.shouldTurn ? " - intors" : "" }})
              </div>
              <ImpositionVisualizer
                label="Label"
                :imposition="value"
                :printersById="printersById"
              />
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      custom-class="w-auto m-5"
      title="Atentie!"
      :visible.sync="stockDialog"
      append-to-body
      center
    >
      <div class="text-xl text-center">
        Ai scazut stoc materiale ?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="xl" type="primary" @click="removeDefaultStock"
          >SCADERE STOCURI (AUTOMAT)</el-button
        >
        <el-button icon="el-icon-check" size="xl" @click="promptResult(true)"
          >FINALIZARE SARCINA</el-button
        >
      </span>
    </el-dialog>
  </ElDialog>
</template>

<script>
import { mapKeys } from "lodash"
import {
  evaluateInContext,
  countUniques,
  getMaterialQuantityTotals,
} from "@/calculator"
import keyBy from "lodash/keyBy"
import ImpositionVisualizer from "@/components/utils/ImpositionVisualizer"
// function isNumeric(str) {
//   if (typeof str != "string") return false; // we only process strings!
//   return (
//     !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
//   ); // ...and ensure strings of whitespace fail
// }
export default {
  props: [
    "employees",
    "clientsById",
    "printersById",
    "operationsById",
    "materialsById",
    "workstationsById",
  ],
  components: {
    ImpositionVisualizer,
  },
  data() {
    return {
      hasReadInstructions: false,
      currentTab: "0",
      isFinal: false,
      isWorkStationWithResultText: false,
      visible: false,
      task: null,
      stocks: [],
      stockDialog: false,
    };
  },
  computed: {
    employeesById() {
      return keyBy(this.employees, "id");
    },
    context() {
      return this.task.project.data.variables;
    },
    operations() {
      return countUniques(this.task.operations);
    },
    materials() {
      return countUniques(this.task.materials);
    },
    impositions() {
      return this.task.component
        ? countUniques(this.task.component.impositions)
        : [];
    },
    title() {
      if (!this.task) return "";
      const taskTitle = this.task.component
        ? `${this.task.name} ${this.task.component.name}`
        : this.task.name;
      const projectTitle = `#${this.task.project.data.number} ${this.task.project.data.name}`;
      return `${taskTitle} | ${projectTitle}`;
    },
    quantityTotals() {
      return getMaterialQuantityTotals([this.task], this.context);
    },
    quantityTotalsByMaterials() {
      return this.materials.map(
        (material) => material.count * this.evaluate(material.value.quantity)
      );
    },
  },
  methods: {
    nextStage() {
      this.hasReadInstructions = true
      this.currentTab = "2"
    },
    saveResult() {
      this.updateTask({ result: this.task.result });
      this.$store.addActivity(
        `La Sarcina "${this.task.name}" - modificata cantiatea`,
        this.task.project.id,
        this.task.employeeIds,
        this.task.result
      );
    },
    restrictNull(index, x) {
      this.stocks[index] = !x ? 0 : x;
    },
    open(task) {
      this.visible = true;
      this.task = task;
      this.hasReadInstructions = task.active ? true : false
      this.isFinal = this.finalTask(task.project) === task.id;
      this.checkWorkStationWithResultText(task);
      this.stocks = task.debitedQuantities
        ? task.debitedQuantities
        : Object.values(this.quantityTotalsByMaterials);
    },
    async completeProject(finalQuantity, resultText) {
      try {
        const project = this.task.project;
        project.data.stage = "completed";
        project.data.finalQuantity = finalQuantity;
        project.data.resultText = resultText;
        await this.$firestore()
          .doc(`projects/${project.id}`)
          .update({
            ...project.data,
            updatedAt: this.$firestore.FieldValue.serverTimestamp(),
          });
        this.$message.success("Modificarea a fost efectuata.");
      } catch (error) {
        this.$message.error("Oops, a intervenit o eroare.");
      }
    },
    async updateTask(data) {
      try {
        const { id, project } = this.task;
        Object.keys(data).forEach((key) => (this.task[key] = data[key]));
        await this.$firestore()
          .doc(`projects/${project.id}`)
          .update({
            ...mapKeys(data, (value, key) => `tasks.${id}.${key}`),
            updatedAt: this.$firestore.FieldValue.serverTimestamp(),
          });
        // this.$message.success("Succes");
        this.$message({
          message: "Succes",
          type: "success",
          duration: "1000",
        });
      } catch (error) {
        this.$message.error("Oops, a intervenit o eroare.");
      }
    },
    updateEmployees(employeeIds) {
      this.updateTask({ employeeIds });
    },
    checkWorkStationWithResultText(task) {
      if (
        task.workstationIds &&
        Object.values(task.workstationIds).length !== 0
      ) {
        this.isWorkStationWithResultText =
          Object.values(task.workstationIds).filter(
            (x) => this.workstationsById[x].data.withResultText
          ).length !== 0;
      } else {
        this.isWorkStationWithResultText = false;
      }
    },
    toggleActive(active) {
      // if (!active) {
      //   this.promptResult(false);
      // } else {
      this.updateTask({ active, break: false, completed: false });
      this.$store.addActivity(
        `Sarcina "${this.task.name}" a fost ${
          active ? "inceputa" : "setata ca ne-inceputa"
        }`,
        this.task.project.id,
        this.task.employeeIds,
        this.task.result
      );
      // }
    },
    toggleBreak(active) {
      if (active) {
        this.promptResult(false);
      } else {
        this.updateTask({ break: active });
        this.$store.addActivity(
          `Sarcina "${this.task.name}" a fost ${
            !active ? "inceputa" : "pusa pe pauza"
          }`,
          this.task.project.id,
          this.task.employeeIds,
          this.task.result
        );
      }
    },
    finalTask(row) {
      let sourcesList = Object.values(row.data.edges).map((x) => x.source);
      let listOfTasks = Object.keys(row.data.tasks);
      let finalTasksList = listOfTasks.filter(
        (x) => sourcesList.indexOf(x) === -1
      );
      if (finalTasksList.length) {
        return finalTasksList[0];
      }
      return null;
    },
    checkToggle(completed) {
      if (completed) {
        if (this.task.materials.length !== 0 && !this.task.materialsDebited) {
          //dc lucreaza cu materiale si materiale nu sunt debitate.
          this.stockDialog = true;
          this.task.completed = false;
        } else {
          this.promptResult(true);
        }
      } else {
        if (this.task.materialsDebited && this.task.materials.length) {
          // console.log("punem la loc");
          this.creditMaterials(this.task.debitedQuantities);
        }
        this.updateTask({ result: "", active: false, completed: false });
        // this.completeTask(false);
      }
    },
    removeDefaultStock() {
      this.debitMaterials(true);
      this.stocks = this.task.debitedQuantities
        ? this.task.debitedQuantities
        : Object.values(this.quantityTotalsByMaterials);
      this.stockDialog = false;
      this.promptResult(true);
    },
    promptResult(completedState) {
      this.$prompt("Rezultat ", this.isFinal ? "Rezultat(numar)" : "Rezultat", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        inputPattern: this.isFinal ? /\d/ : "",
        inputErrorMessage: "Rezultat invalid",
      })
        .then(({ value }) => {
          this.stockDialog = false;
          // this.updateTask({ result: value, active: false, completed: true });

          // set project as complete if final task.
          if (this.isFinal && completedState) {
            if (this.isWorkStationWithResultText) {
              var resultText = prompt("Serie", "");
              this.completeProject(value, resultText);
            } else {
              this.completeProject(value, null);
            }
          } else {
            if (this.task.name === "Formatizare 1" && !value) {
              if (!this.task.debitedQuantities) {
                const reducer = (accumulator, item) =>
                  accumulator +
                  item.count *
                    this.evaluate(item.value.quantity) *
                    this.evaluate(item.value.parameter);

                value = this.operations.reduce(reducer, 0);
              } else {
                let sum = 0;
                this.operations.forEach((operation, index) => {
                  sum +=
                    parseInt(
                      Object.values(this.task.debitedQuantities)[index]
                    ) * parseInt(this.evaluate(operation.value.parameter));
                });
                value = sum;
                // value = this.task.debitedQuantities.reduce(reducer, 0);
              }
            }
          }
          //reset break if finalizat
          if (completedState) {
            this.task.break = false;
          }
          this.updateTask({
            result: value,
            completed: completedState,
            break: this.task.break,
          });

          this.$store.addActivity(
            completedState
              ? `Sarcina "${this.task.name}" a fost finalizata`
              : `Sarcina "${this.task.name}" a fost oprita`,
            this.task.project.id,
            this.task.employeeIds,
            this.task.result
          );
        })
        .catch(() => {
          this.task.completed = false;
          // this.completeTask(false);
          this.$message({
            type: "info",
            message: "Input canceled",
          });
        });
    },
    async updateMaterials(sign, setDefaultValues = false) {
      const batch = this.$firestore().batch();
      Object.entries(this.quantityTotals).forEach((entry, index) => {
        const [materialId, quantity] = entry;
        const quantityV = setDefaultValues
          ? parseFloat(quantity)
          : parseFloat(this.stocks[index]);
        batch.update(this.$firestore().doc(`materials/${materialId}`), {
          currentQuantity: this.$firestore.FieldValue.increment(
            quantityV * sign
          ),
        });
      });
      batch.commit();
    },
    debitMaterials(setDefaultValues) {
      this.updateMaterials(-1, setDefaultValues);
      this.updateTask({
        materialsDebited: true,
        debitedQuantities: setDefaultValues ? null : this.stocks,
      });
    },
    creditMaterials(setDefaultValues) {
      this.updateMaterials(+1);
      this.updateTask({
        materialsDebited: false,
        debitedQuantities: setDefaultValues ? null : this.stocks,
      });
    },
    evaluate(expr) {
      try {
        return evaluateInContext(expr, this.context);
      } catch (error) {
        return "???";
      }
    },
  },
};
</script>
