<template>
  <div class="flex mb-5">
    <!-- page -->
    <div class="mr-6">
      <div>Pagina</div>
      <div>
        {{ imposition.pageSize.width | number }} x
        {{ imposition.pageSize.height | number }}
      </div>
      <div class="mt-2" :style="getParentStyle(imposition.pageSize)">
        <div
          v-for="(child, index) in getChildren({ rows: 1, columns: 1 })"
          :key="index"
          class="bg-green-500"
          :style="getChildStyle(child)"
        ></div>
      </div>
    </div>

    <!-- final sheet -->
    <div class="mr-6">
      <div>Coala Finala</div>
      <div>
        {{ imposition.finalSheetSize.width | number }} x
        {{ imposition.finalSheetSize.height | number }}
      </div>
      <div
        class="mt-2"
        :style="{ ...getParentStyle(imposition.pageSize), gridGap: 0 }"
      >
        <div
          v-for="(child, index) in getChildren(imposition.finalSheetLayout)"
          :key="index"
          class="bg-red-500"
          :style="{ ...getChildStyle(child), border: '1px dashed white' }"
        ></div>
      </div>
    </div>

    <!-- print sheet -->
    <div class="mr-6">
      <div class="font-bold">Coala Tipar</div>
      <div class="font-bold">
        {{ imposition.printSheetSize.width | number }} x
        {{ imposition.printSheetSize.height | number }}
      </div>
      <div class="mt-2" :style="getParentStyle(imposition.finalSheetSize)">
        <div
          v-for="(child, index) in getChildren(imposition.printSheetLayout)"
          :key="index"
          class="bg-orange-500"
          :style="getChildStyle(child)"
        ></div>
      </div>
    </div>

    <!-- large sheet -->
    <div>
      <div>Coala Mare</div>
      <div>
        {{ imposition.largeSheetSize.width | number }} x
        {{ imposition.largeSheetSize.height | number }}
      </div>
      <div class="mt-2" :style="getParentStyle(imposition.printSheetSize)">
        <div
          v-for="(child, index) in getChildren(imposition.largeSheetLayout)"
          :key="index"
          class="bg-yellow-500"
          :style="getChildStyle(child)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["imposition", "printersById"],
  methods: {
    getParentStyle({ width, height }, scale = 0.2) {
      return {
        display: "grid",
        "grid-gap": "4px",
        "grid-auto-rows": `${height * scale}px`,
        "grid-auto-columns": `${width * scale}px`
      };
    },
    getChildStyle({ row, column }) {
      return {
        "grid-row": row,
        "grid-column": column
      };
    },
    getChildren({ rows, columns }) {
      const children = [];

      for (let row = 1; row <= rows; row++) {
        for (let column = 1; column <= columns; column++) {
          children.push({ row, column });
        }
      }

      return children;
    }
  }
};
</script>
